@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('./assets/font/style.css');
@import url('https://use.typekit.net/kqu0qva.css');
@import 'main.scss';
* {
  margin: 0;
  padding: 0;
  font-family: 'canada-type-gibson';
}

.wrapper {
  background-color: white;
}

$containerWidth: '560px';

// dialouge box
.cdk-overlay-pane {
  max-width: 100vw !important;
  // @media screen and (max-width: 600px) {
  //   max-width: 85vw;
  // }
}

.mat-calendar-body-selected {
  background-color: $primary-gold !important;
  color: #fff;
}

.mat-datepicker-popup {
  width: auto;
}

.contact {
  color: $primary-gold;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: auto;
  overflow-x: hidden;
  height: auto !important;
  background: transparent;
  box-shadow: none !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
}

input:focus {
  font-size: 14px;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.6;
}

@media print {
  // body {
  //   zoom: 0.8; 
  // }
  @page {
      size: A4 landscape;
  }
  .doNotPrint {
    display: none !important;
  }
}


.flip-horizontally {
  transform: scaleX(-1);
}

:host ::ng-deep .modal-radius {
  border-radius: 20px;
}

img.arrow {
  max-height: 22px;
}
