// Color Variables

$primary-gold: #88714d;
$primary-grey: #f2f2f2;
$primary-black: #2a2e34;
$black: #0e0f0f;
$hover: #bbad99;
$error: #ef3340;
$white: #ffffff;
$nobel-grey: #9b9b9b;
// button width

$button-width: 10vw;

// reponsive variable

$tab: 1150px;
$mobile: 600px;

$seperator: 0.3px solid #dae0ea;
