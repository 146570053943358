.light-text {
  color: $nobel-grey;
  font-size: 13px;
}

.w-100 {
  width: 100%;
}

/**
*@flex class
**/
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}

/**
*@spacing
**/
.pl-5 {
  padding-left: 5px;
}
.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
