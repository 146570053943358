.btn {
  border: 1px solid transparent;
  cursor: pointer;
}
.btn-theme {
  border-color: $primary-gold;
  background-color: $primary-gold;
  color: $white;
}
.btn-medium {
  padding: 8px 20px 6px;
}
.btn-bordered-black {
  border-color: $black;
  border-width: 2px;
  font-size: 17px;
  height: 24px;
  width: 24px;
  padding: 4px;
  border-radius: 3px;
  line-height: 15px;
}
.btn-disable {
  border-color: $primary-grey;
  color: $primary-grey;
  pointer-events: none;
  background-color: transparent;
}
